import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
  Collapse,
  FormControlLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./SideMenu.scss";
import LogoDark from "../../../../assets/homepage/heroSection/logo.png";
import LogoLight from "../../../../assets/homepage/lowerFooterSection/footer_logo.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../lib/consts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { toggleDarkMode } from "../../../../store/actions/appAction";
import {
  Apps,
  AppsOutage,
  AppsOutlined,
  AppsRounded,
  ExpandLess,
  ExpandMore,
  LogoutOutlined,
  StarBorder,
  SubdirectoryArrowRight,
  SummarizeOutlined,
} from "@mui/icons-material";
import { logoutUser } from "../../../../lib/utils";
import useUserInfo from "../../../../hooks/useUserInfo";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
type MenuItemProp = {
  icon: React.ReactElement<SvgIconProps>;
  title: string;
  route: string | null;
  onClick?: () => void;
  children?: MenuItemProp[];
};

const SideMenu = () => {
  const [isExpanded, setIsExpanded] = useState(window.innerWidth > 768);
  const dispatch = useDispatch();

  const { isDarkMode } = useSelector((state: RootState) => state.appReducer);

  useEffect(() => {
    const handleResize = () => {
      setIsExpanded(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleDarkMode = () => {
    dispatch(toggleDarkMode());
  };
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { reportsList, selectedApp, appsCount } = useUserInfo();

  const [expandMenuItem, setExpandMenuItem] = useState("");

  const mainMenu: MenuItemProp[] = [
    ...(appsCount > 1
      ? [
          {
            title: "View Apps",
            route: ROUTES.APPS,
            icon: <AppsRounded />,
          },
        ]
      : []),
    {
      title: "Dashboard",
      route: selectedApp?.key
        ? ROUTES.ANALYTICS(selectedApp?.key)
        : ROUTES.APPS,
      icon: <GridViewOutlinedIcon />,
    },
    ...(reportsList && reportsList.length > 0
      ? [
          {
            title: "View Reports",
            route: "",
            icon: <AssessmentOutlinedIcon />,
            children: reportsList.map((item) => {
              return {
                title: item.title,
                route: ROUTES.REPORT(item.key),
                icon: <SummarizeOutlined />,
              };
            }),
          },
        ]
      : []),
  ];

  return (
    <div
      className={`side-menu ${isExpanded ? "expanded" : ""}`}
      onMouseLeave={() => {
        if (window.innerWidth <= 768) {
          setIsExpanded(true);
        }
      }}
    >
      <div className="close-button">
        <button
          onClick={() => {
            if (window.innerWidth <= 768) {
              setIsExpanded(false);
            }
          }}
        >
          <CloseOutlinedIcon />
        </button>
      </div>
      <div className="head">
        {isExpanded ? (
          <img src={LogoLight} alt="Logo" className="logo" />
        ) : (
          <MenuOutlinedIcon
            fontSize="large"
            onClick={() => setIsExpanded(true)}
          />
        )}
      </div>
      <div className="content">
        {mainMenu.map((item) => (
          <>
            <ListItemButton
              className="menu-item"
              key={`main-menu-${item.title}`}
              selected={pathname === item.route}
              onClick={() => {
                if (item.children) {
                  setExpandMenuItem(
                    expandMenuItem === item.title ? "" : item.title
                  );
                } else {
                  if (item.route) navigate(item.route);
                }
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {isExpanded && (
                <ListItemText primary={item.title} sx={{ margin: 0 }} />
              )}
              {item.children && isExpanded ? (
                expandMenuItem === item.title ? (
                  <ListItemIcon>
                    <ExpandLess />
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>
                    <ExpandMore />
                  </ListItemIcon>
                )
              ) : (
                <></>
              )}
            </ListItemButton>
            {item.children && (
              <Collapse
                in={expandMenuItem === item.title}
                timeout="auto"
                unmountOnExit
                key={`main-menu-${item.title}-children`}
              >
                {item.children.map((child) => (
                  <ListItemButton
                    sx={{
                      padding: "15px",
                    }}
                    key={`main-menu-${child.title}`}
                    selected={pathname === child.route}
                    onClick={() => {
                      if (child.children) {
                        setExpandMenuItem(
                          expandMenuItem === child.title ? "" : child.title
                        );
                      } else {
                        if (child.route) navigate(child.route);
                      }
                    }}
                  >
                    <ListItemIcon>
                      {isExpanded && (
                        <SubdirectoryArrowRight sx={{ marginRight: "10px" }} />
                      )}
                      {child.icon}
                    </ListItemIcon>
                    {isExpanded && (
                      <ListItemText
                        primary={child.title}
                        sx={{ margin: 0, marginLeft: "10px" }}
                      />
                    )}
                  </ListItemButton>
                ))}
              </Collapse>
            )}
          </>
        ))}
      </div>
      <div className="footer">
        <div className="darkmode-wrapper">
          <FormControlLabel
            control={
              <Switch
                onChange={handleToggleDarkMode}
                name="toggleDark"
                color="secondary"
                size="small"
                checked={isDarkMode}
              />
            }
            label={isExpanded ? "Dark Mode" : ""}
          />
        </div>

        <ListItemButton onClick={() => logoutUser()}>
          <ListItemIcon>
            <LogoutOutlined />
          </ListItemIcon>
          {isExpanded && (
            <ListItemText
              primary="Logout"
              sx={{ margin: 0, marginLeft: "10px" }}
            />
          )}
        </ListItemButton>
      </div>
    </div>
  );
};

export default SideMenu;
