import "./style.scss";
import rightArrow from "../../../../assets/homepage/arrow-right.svg";
import userLogo from "../../../../assets/homepage/user.svg";
import password_logo from "../../../../assets/homepage/password_logo.svg";
import eye_open from "../../../../assets/homepage/eye_open.svg";
import eye_close from "../../../../assets/homepage/eye_close.svg";
import { useState } from "react";
import { InputField } from "../inputFieldComponent/InputField";
import { Form, Formik } from "formik";
import { LoginSchema } from "../../../../schemas";
import { Link, useNavigate } from "react-router-dom";
import API from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../../lib/consts";
import { setAccessToken } from "../../../../store/actions/authAction";
import store, { RootState } from "../../../../store/store";
import { toggleDarkMode } from "../../../../store/actions/appAction";

export function LoginForm() {
  const [passwordType, setPasswordType] = useState("password");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDarkMode } = useSelector((state: RootState) => state.appReducer);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <section className="login-div">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          API.login(values.email, values.password).then((resp) => {
            dispatch(setAccessToken(resp.data.access_token));
            if (resp.data.appKey) {
              navigate(ROUTES.ANALYTICS(resp.data.appKey));
            } else {
              navigate(ROUTES.APPS);
            }

            if (
              window.matchMedia("(prefers-color-scheme: dark)").matches &&
              !isDarkMode
            ) {
              store.dispatch(toggleDarkMode());
            }
          });
        }}
      >
        {({ errors, touched }) => (
          <Form className="formValidation">
            <div className="form-wrapper">
              <div className="login-reg">
                <div className="login-email">
                  <div className="emailInput">
                    <img className="userLogo" src={userLogo} alt={"userLogo"} />
                    <InputField
                      placeholder="Enter email or user name"
                      type="text"
                      name="email"
                      isFormikRequired={true}
                    />
                  </div>
                </div>
                {errors.email && touched.email ? (
                  <div className="error-mob">{errors.email}</div>
                ) : null}
                <div className="login-input-divider" />
                <div className="login-password">
                  <div className="passwordInput">
                    <img
                      className="passwordLogo"
                      src={password_logo}
                      alt={"password_logo"}
                    />
                    <InputField
                      placeholder="Password"
                      type={passwordType}
                      name="password"
                      isFormikRequired={true}
                    />
                    {passwordType === "password" ? (
                      <img
                        className="eyeLogo"
                        src={eye_close}
                        alt="eye_close"
                        onClick={() => togglePassword()}
                      />
                    ) : (
                      <img
                        className="eyeLogo"
                        src={eye_open}
                        alt="eye_open"
                        onClick={() => togglePassword()}
                      />
                    )}
                  </div>
                </div>
                {errors.password && touched.password ? (
                  <div className="error-mob">{errors.password}</div>
                ) : null}
              </div>
              <button type="submit" className="login-button">
                Login
                <img src={rightArrow} alt="rightArrow" />
              </button>
            </div>
            <div className="err-div">
              {errors.email && touched.email ? (
                <div className="error">{errors.email}</div>
              ) : null}
              {errors.password && touched.password ? (
                <div className="error psw">{errors.password}</div>
              ) : null}
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
}
